import React, { useRef, useState, useContext } from "react";

//Node Modules
import { useTranslation } from "react-i18next";
import { Overlay, Popover } from "react-bootstrap";

//Styles
import styles from "./ShareUrl.module.scss";

import { DisableShareUrlButtonContext } from "context/disable-share-url-button-context";

const ShareUrl = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [copied, setCopied] = useState(false);
  const disableShareUrl = useContext(DisableShareUrlButtonContext);

  function copyUrl(event: React.MouseEvent<HTMLButtonElement>) {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    let copyUrlButton = document.getElementById("copyUrl");
    copyUrlButton?.focus();
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  const emailBody = encodeURIComponent(window.location.href);
  const emailSubject = t(
    "releasenotes-gui-icu.releasenotes.share_email_subject.msg"
  );
  const emailMailto = `mailto:?subject=${emailSubject}&body=${emailBody}`;
  const emailLink = (
    <a href={emailMailto} className={styles.mailtoHref}>
      <span className={styles.shareItemSpan}>
        {t("releasenotes-gui-icu.releasenotes.share_email_this_page.msg")}
      </span>
    </a>
  );

  const popover = (
    <Popover id="shareButton">
      <Popover.Content bsPrefix={styles.popoverBody}>
        <button
          id="copyUrl"
          className={`${copied ? styles.shareItemButtonCopied : styles.shareItemButton}`}
          title={
            copied
              ? t("releasenotes-gui-icu.releasenotes.share_url_copied.msg")
              : t("releasenotes-gui-icu.releasenotes.share_copy_url.msg")
          }
          onClick={copyUrl}
        >
          <span className={styles.shareItemButtonSpan}>
            {copied ? (
              <img
                src="images/menuCheck.svg"
                alt={t(
                  "releasenotes-gui-icu.releasenotes.share_url_copied.msg"
                )}
                className={styles.urlCopiedImg}
              />
            ) : (
              ""
            )}
          </span>
          <span className={styles.shareItemSpan}>
            {copied
              ? t("releasenotes-gui-icu.releasenotes.share_url_copied.msg")
              : t("releasenotes-gui-icu.releasenotes.share_copy_url.msg")}
          </span>
        </button>
      </Popover.Content>
      <Popover.Content bsPrefix={styles.popoverBody}>
        <button className={styles.shareItemButton}>
          <span className={styles.shareItemButtonSpan}></span>
          {emailLink}
        </button>
      </Popover.Content>
    </Popover>
  );

  return (
    <>
      {
        <span>
          <button
            className={styles.shareImageContainer}
            ref={target}
            disabled={disableShareUrl ? true : false}
          >
            <img
              onClick={() => setShow(!show)}
              className={
                disableShareUrl ? styles.shareImgDisabled : styles.shareImg
              }
              src="images/share.svg"
              alt={t("releasenotes-gui-icu.releasenotes.share_button.msg")}
              title={t("releasenotes-gui-icu.releasenotes.share_button.msg")}
            />
          </button>
          <Overlay
            target={target.current}
            show={show}
            rootClose
            onHide={() => setShow(false)}
            container={target.current}
            placement="bottom-end"
          >
            {popover}
          </Overlay>
        </span>
      }
    </>
  );
};

export default ShareUrl;
