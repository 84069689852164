import { SVGProps as ReactSVGProps, ReactElement } from "react";

export interface SVGIconDescriptor {
  name: string;
  render(props: ReactSVGProps<SVGSVGElement>): ReactElement;
}

export type SVGProps = {
  icon: SVGIconDescriptor;
} & ReactSVGProps<SVGSVGElement>;

const SASIcon = ({ icon, ...props }: SVGProps) => {
  return icon.render(props);
};

export default SASIcon;
