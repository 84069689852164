import React from "react";
import { useTranslation } from "react-i18next";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";

//Util
import { keypressHandler } from "util/keypressHandler";
import { MY_SAS_LINK, HELP_DOCSET_LINK } from "util/constants";

//Hooks
import { useIsSmallDevice } from "hooks";

import styles from "./Header.module.scss";

interface Props {
  paramOrder?: string;
  hideSasIcon: boolean;
}

const Header = ({ paramOrder, hideSasIcon }: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  const backToMySas = () => {
    window.open(MY_SAS_LINK, "_blank");
  };

  return (
    <>
      {!hideSasIcon ? (
        <Navbar
          className={`${styles.header} ${
            isSmallDevice ? styles.smallDeviceTopHeader : styles.topHeader
          }`}
          variant="dark"
        >
          <Nav>
            <Nav.Link
              href="http://www.sas.com/index.html"
              target="_blank"
              onKeyDown={keypressHandler}
              rel="noopener"
            >
              <img
                className={styles.logo}
                src="images/sas-logo-white.png"
                alt="SAS The Power to Know. Providing software solutions since 1976"
              />
            </Nav.Link>
          </Nav>
          {paramOrder ? (
            <Nav>
              <button
                className={
                  isSmallDevice
                    ? styles.smallDeviceBackToMySas
                    : styles.backToMySas
                }
                type="button"
                onClick={backToMySas}
              >
                {t("releasenotes-gui-icu.releasenotes.back_to_my_sas.msg")}
              </button>
            </Nav>
          ) : (
            ""
          )}
        </Navbar>
      ) : (
        ""
      )}

      <Navbar
        className={`${styles.header} ${styles.banner} ${
          isSmallDevice ? styles.smallDevicePadding : styles.padding
        }`}
      >
        <Nav className={styles.title}>
          {t("releasenotes-gui-icu.releasenotes.banner_title_sas.msg")}&reg;{" "}
          {t("releasenotes-gui-icu.releasenotes.banner_title_viya.msg")}
        </Nav>
        <Nav className="ml-auto">
          <span className={styles.helpButtonContainer}>
            <a target="_blank" href={HELP_DOCSET_LINK} rel="noreferrer">
              <Image
                className={styles.helpButtonToolbar}
                src="images/helpButtonToolbar.svg"
              />
            </a>
          </span>
        </Nav>
      </Navbar>
    </>
  );
};

export default Header;
