import { useState, useEffect } from "react";

/*
  IE and Safari do not support MediaQueryList extending EventTarget.  As such, they do not recognize
  addEventListener and removeEventListener.  The issue is that addListener and removeListener are
  deprecated and may be removed at some point in the future.

  https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#Browser_compatibility
*/

export const useMediaQuery = (query: string): boolean => {
  const [doesMatch, onSetDoesMatch] = useState(false);

  useEffect(() => {
    const onUpdateMatch = ({ matches }: any) => {
      onSetDoesMatch(matches);
    };

    const matcher = window.matchMedia(query) as any;

    matcher.addListener(onUpdateMatch);

    const isModern = "addEventListener" in matcher;
    if (isModern) {
      matcher.addEventListener("change", onUpdateMatch);
    } else {
      matcher.addListener(onUpdateMatch);
    }

    onUpdateMatch(matcher);

    return () => {
      if (isModern) {
        matcher.removeEventListener("change", onUpdateMatch);
      } else {
        matcher.removeListener(onUpdateMatch);
      }
    };
  }, [query, onSetDoesMatch]);

  return doesMatch;
};
