import React from "react";

//Node Modules
import { useTranslation } from "react-i18next";

//Components
import ResultReleaseNote from "components/ResultReleaseNote";
import ExpandCollapseIcon from "components/ExpandCollapseIcon";

//Types
import { FilterResultTypeWrapper } from "types/filterResultWrapper";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./ResultReleaseNoteType.module.scss";

//Util
import {
  TYPE_WHATSNEW,
  TYPE_DEPLOYMENTNOTES,
  TYPE_DEPRECATED,
  TYPE_CRITICAL
} from "util/constants";

interface Props {
  expandCollapseIconId: string;
  typeReleaseNotes: FilterResultTypeWrapper[];
  source: string;
  expandedResults: string[];
  handleResultToggle: (id: string) => void;
}

const ResultReleaseNoteType = ({
  expandCollapseIconId,
  typeReleaseNotes,
  source,
  expandedResults,
  handleResultToggle,
}: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  const getId = (codeText: string): string => {
    return expandCollapseIconId + "-releaseNoteType-" + codeText;
  };

  const findItem = (id: string): boolean => {
    return expandedResults.includes(id);
  };

  // Get L10N flavor of category display value
  const getReleaseNoteTypeDisplayValue = (releaseNoteType: string): string => {
    switch (releaseNoteType) {
      case TYPE_WHATSNEW: {
        return t("releasenotes-gui-icu.releasenotes.filter_type_whatsnew.msg");
      }
      case TYPE_CRITICAL: {
        return t(
          "releasenotes-gui-icu.releasenotes.filter_type_critical.msg"
        );
      }
      case TYPE_DEPRECATED: {
        return t(
          "releasenotes-gui-icu.releasenotes.filter_type_deprecated.msg"
        );
      }
      case TYPE_DEPLOYMENTNOTES: {
        return t(
          "releasenotes-gui-icu.releasenotes.filter_type_deployment_note.msg"
        );
      }
      default: {
        return "";
      }
    }
  };

  return (
    <>
      {typeReleaseNotes?.map((type) => {
        return (
          <div key={type.releaseNoteTypeCodeText}>
            <div
              className={
                isSmallDevice
                  ? styles.releaseNoteTypeSmallDevice
                  : styles.releaseNoteType
              }
              key={type.releaseNoteTypeCodeText}
            >
              <div className={styles.innerDiv}>
                <div>
                  <ExpandCollapseIcon
                    id={getId(type.releaseNoteTypeCodeText)}
                    expand={
                      findItem(getId(type.releaseNoteTypeCodeText))
                        ? true
                        : false
                    }
                    handleResultToggle={handleResultToggle}
                    ariaLabel={type.releaseNoteType}
                  ></ExpandCollapseIcon>
                </div>
                <div>
                  <span className={styles.header}>
                    {getReleaseNoteTypeDisplayValue(
                      type.releaseNoteTypeCodeText
                    )}
                  </span>
                </div>
              </div>
              {findItem(getId(type.releaseNoteTypeCodeText)) && (
                <div id={getId(type.releaseNoteTypeCodeText)}>
                  <ResultReleaseNote
                    releaseNotes={type.releaseNotes}
                  ></ResultReleaseNote>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ResultReleaseNoteType;
