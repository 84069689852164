import React from "react";

//Node Modules
import { useTranslation } from "react-i18next";

//Components
import ResultReleaseNoteType from "components/ResultReleaseNoteType";
import ExpandCollapseIcon from "components/ExpandCollapseIcon";

//Types
import { FilterResultProductWrapper } from "types/filterResultWrapper";

//Util
import {
  CATEGORY_PROGRAMMING,
  CATEGORY_DEPLOYMENT,
  CATEGORY_ADMINISTRATION,
} from "util/constants";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./ResultCategory.module.scss";

interface Props {
  cadence: string;
  productReleaseNotes: FilterResultProductWrapper[];
  expandedResults: string[];
  handleResultToggle: (id: string) => void;
}

const ResultCategory = ({
  cadence,
  productReleaseNotes,
  expandedResults,
  handleResultToggle,
}: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  const getId = (codeText: string): string => {
    return cadence + "-category-" + codeText;
  };

  const findItem = (id: string): boolean => {
    return expandedResults.includes(id);
  };

  // Get L10N flavor of category display value
  const getCategoryDisplayValue = (category: string): string => {
    switch (category) {
      case CATEGORY_PROGRAMMING: {
        return t(
          "releasenotes-gui-icu.releasenotes.filter_category_programming.msg"
        );
      }
      case CATEGORY_ADMINISTRATION: {
        return t(
          "releasenotes-gui-icu.releasenotes.filter_category_administration.msg"
        );
      }
      case CATEGORY_DEPLOYMENT: {
        return t(
          "releasenotes-gui-icu.releasenotes.filter_category_deployment.msg"
        );
      }
      default: {
        return "";
      }
    }
  };

  //https://www.geeksforgeeks.org/how-to-show-or-hide-element-in-react/
  //https://stackoverflow.com/questions/24502898/show-or-hide-element-in-react

  return (
    <>
      {productReleaseNotes?.map((product) => {
        return (
          <div
            className={
              isSmallDevice ? styles.categorySmallDevice : styles.category
            }
            key={product.productIdentifierCodeText}
          >
            <div className={styles.innerDiv}>
              <ExpandCollapseIcon
                id={getId(product.productIdentifierCodeText)}
                expand={
                  findItem(getId(product.productIdentifierCodeText))
                    ? true
                    : false
                }
                handleResultToggle={handleResultToggle}
                ariaLabel={product.productIdentifier}
              ></ExpandCollapseIcon>
              <span className={styles.header}>
                {getCategoryDisplayValue(product.productIdentifierCodeText)}
              </span>
            </div>
            {findItem(getId(product.productIdentifierCodeText)) && (
              <div id={getId(product.productIdentifierCodeText)}>
                <ResultReleaseNoteType
                  expandCollapseIconId={getId(
                    product.productIdentifierCodeText
                  )}
                  typeReleaseNotes={product.typeReleaseNotes}
                  source="category"
                  expandedResults={expandedResults}
                  handleResultToggle={handleResultToggle}
                ></ResultReleaseNoteType>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ResultCategory;
