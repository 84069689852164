import React, { useContext, useEffect } from "react";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./Checkbox.module.scss";
import {
  TYPE_WHATSNEW,
  TYPE_DEPRECATED,
  CHECKBOX_ID_WHATSNEW,
} from "util/constants";

//Context
import { SelectedReleaseNoteTypesContext } from "context/selected-filters";

interface Props {
  id: string;
  label: any;
  title?: string;
  isChecked?: boolean;
  type: string;
  handleChange: (codeText: string, type: string) => void;
}

const Checkbox = ({
  id,
  label,
  title,
  isChecked,
  type,
  handleChange,
}: Props) => {
  const isSmallDevice = useIsSmallDevice();
  const selectedReleaseNoteTypesContext = useContext(
    SelectedReleaseNoteTypesContext
  );

  useEffect(() => {
    checkTriState();
  });

  // When the component is created, check what the selected release note types are. If deprecated is checked but whatsNew is not,
  // add replace whatsNew checkbox class with conditionalCheckbox so it shows tri-state.
  function checkTriState() {
    let whatsNewElement = document.getElementById(CHECKBOX_ID_WHATSNEW);
    if (
      !selectedReleaseNoteTypesContext?.includes(TYPE_WHATSNEW) &&
      selectedReleaseNoteTypesContext?.includes(TYPE_DEPRECATED)
    ) {
      whatsNewElement?.classList.remove(styles.checkbox);
      whatsNewElement?.classList.add(styles.conditionalCheckbox);
    }
  }

  //13: Enter key, 32: Space key
  function keypressHandler(e: React.KeyboardEvent) {
    if (e.keyCode !== 9) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (e.keyCode === 13 || e.keyCode === 32) {
      handleChange(id, type);
    }
  }

  function onclick_handleChange(id: string, type: string) {
    let whatsNewElement = document.getElementById(CHECKBOX_ID_WHATSNEW);
    // When deprecated is clicked (the previously state isChecked is no), put whatsNew to conditional highlighting;
    if (id === TYPE_DEPRECATED && !isChecked) {
      whatsNewElement?.classList.remove(styles.checkbox);
      whatsNewElement?.classList.add(styles.conditionalCheckbox);
    }
    // When deprecated is un-clicked (the previously state isChecked is yes), put whatsNew back to original checkbox styling;
    if (id === TYPE_DEPRECATED && isChecked) {
      whatsNewElement?.classList.add(styles.checkbox);
      whatsNewElement?.classList.remove(styles.conditionalCheckbox);
    }
    // When whatsNew is clicked, remove the conditional highlighting style.
    else if (id === TYPE_WHATSNEW) {
      whatsNewElement?.classList.remove(styles.conditionalCheckbox);
      whatsNewElement?.classList.add(styles.checkbox);
    }
    handleChange(id, type);
  }

  return (
    <div className={styles.field} title={title}>
      <input
        type="checkbox"
        id={type + "-" + id}
        className={`${isSmallDevice ? styles.smallDeviceInput : ""} ${styles.checkbox}`}
        value={id}
        checked={isChecked}
        onClick={() => onclick_handleChange(id, type)}
        onChange={() => onclick_handleChange(id, type)}
        onKeyDown={keypressHandler}
      />
      <label
        className={isSmallDevice ? styles.smallDeviceLabel : styles.label}
        htmlFor={type + "-" + id}
      >
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
