import React, { useState } from "react";

//Node Modules
import { useTranslation } from "react-i18next";

//Types
import { Cadence } from "types/wrapperData";

//Util
import { FILTER_FROM, FILTER_TO, STRING_LENGTH } from "util/constants";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./FilterCadenceRange.module.scss";
import FilterCadenceRangeModal from "components/FilterCadenceRangeModal";

interface Props {
  selectedCadences: string[];
  versions?: Cadence[];
  typeOfCadence: string;
  handleChange: (
    codeText: string,
    typeOfCadence: string,
    fromOrTo?: string
  ) => void;
  selectedCadenceRange?: string;
  selectedCadenceRangeFrom?: string;
  selectedCadenceRangeTo?: string;
}

const FilterCadenceRange = ({
  versions,
  typeOfCadence,
  handleChange,
  selectedCadenceRange,
  selectedCadenceRangeFrom,
  selectedCadenceRangeTo,
}: Props) => {
  const { t } = useTranslation();
  let disabled = versions?.length === 1 ? true : false;
  const isSmallDevice = useIsSmallDevice();
  const [showCadenceModal, setShowCadenceModal] = useState(false);
  const [selectedFromOrTo, setSelectedFromOrTo] = useState("");
  const [showLongWidth, setShowLongWidth] = useState(false);

  const getItem = (codeText: string) => {
    let items = versions?.filter((e) => {
      return e.codeText === codeText;
    });
    return items !== undefined && items.length > 0 ? items[0] : null;
  };

  const getDisplayValue = (codeText?: string) => {
    let items = versions?.filter((e) => {
      return e.codeText === codeText;
    });
    if (items !== undefined && items.length > 0) {
      return items[0].displayValue;
    } else {
      return t("releasenotes-gui-icu.releasenotes.filter_version.msg");
    }
  };

  const [fromDisplayValue, setFromDisPlayValue] = useState(
    getDisplayValue(selectedCadenceRangeFrom)
  );
  const [toDisplayValue, setToDisPlayValue] = useState(
    getDisplayValue(selectedCadenceRangeTo)
  );

  function handleSelect(fromOrTo: string, version: string) {
    let versionItem = getItem(version);
    if (fromOrTo === FILTER_FROM) {
      setFromDisPlayValue(versionItem ? versionItem.displayValue : "");
    } else if (fromOrTo === FILTER_TO) {
      setToDisPlayValue(versionItem ? versionItem.displayValue : "");
    }
    handleChange(version, typeOfCadence, fromOrTo);

    if (versionItem && versionItem.displayValue.length > STRING_LENGTH) {
      setShowLongWidth(true);
    }
  }

  //13: Enter key, 32: Space key
  function keypressHandler(fromOrTo: string, e: React.KeyboardEvent) {
    if (e.keyCode === 13 || e.keyCode === 32) {
      setShowCadenceModal(true);
      setSelectedFromOrTo(fromOrTo);
    }
  }

  const onClick = (fromOrTo: string) => {
    setShowCadenceModal(true);
    setSelectedFromOrTo(fromOrTo);
  };

  return (
    <>
      <div className={isSmallDevice ? styles.smallDeviceItem : styles.item}>
        <table>
          <tbody>
            <tr>
              <td>
                <span
                  className={`${styles.fromSpan} ${styles.fromSpanPaddingRight}`}
                >
                  {t(
                    "releasenotes-gui-icu.releasenotes.filter_version_from.msg"
                  )}
                </span>
              </td>
              <td>
                {!isSmallDevice ? (
                  <select
                    className={styles.rangeSelect}
                    name={"fromVersion_" + typeOfCadence}
                    disabled={disabled}
                    value={selectedCadenceRangeFrom}
                    onChange={(e) => handleSelect(FILTER_FROM, e.target.value)}
                    onKeyDown={(e) => keypressHandler(FILTER_FROM, e)}
                  >
                    <option value={typeOfCadence + "-" + FILTER_FROM}>
                      {t(
                        "releasenotes-gui-icu.releasenotes.filter_version.msg"
                      )}
                    </option>
                    {versions?.map((version) => {
                      return (
                        <option
                          className={styles.rangeOption}
                          id={version.codeText}
                          value={version.codeText}
                          key={version.codeText}
                        >
                          {version.displayValue}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <button
                    className={`${styles.rangeSelect} ${
                      showLongWidth ? styles.longWidth : styles.regularWidth
                    }`}
                    disabled={disabled}
                    onClick={() => onClick(FILTER_FROM)}
                    onKeyDown={(e) => keypressHandler(FILTER_FROM, e)}
                    aria-label={fromDisplayValue}
                  >
                    {fromDisplayValue}
                  </button>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <span className={styles.toSpan}>
                  {t("releasenotes-gui-icu.releasenotes.filter_version_to.msg")}
                </span>
              </td>
              <td>
                {!isSmallDevice ? (
                  <select
                    className={`${styles.rangeSelect}`}
                    name="toVersion_{typeOfCadence}"
                    disabled={disabled}
                    value={selectedCadenceRangeTo}
                    onChange={(e) => handleSelect(FILTER_TO, e.target.value)}
                    onKeyDown={(e) => keypressHandler(FILTER_TO, e)}
                  >
                    <option value={typeOfCadence + "-" + FILTER_TO}>
                      {t(
                        "releasenotes-gui-icu.releasenotes.filter_version.msg"
                      )}
                    </option>
                    {versions?.map((version) => {
                      return (
                        <option
                          className={styles.rangeOption}
                          value={version.codeText}
                          key={version.codeText}
                        >
                          {version.displayValue}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <button
                    className={`${styles.rangeSelect} ${
                      showLongWidth ? styles.longWidth : styles.regularWidth
                    }`}
                    disabled={disabled}
                    onClick={() => onClick(FILTER_TO)}
                    onKeyDown={(e) => keypressHandler(FILTER_TO, e)}
                    aria-label={toDisplayValue}
                  >
                    {toDisplayValue}
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <FilterCadenceRangeModal
        show={showCadenceModal}
        versions={versions}
        onHide={() => setShowCadenceModal(false)}
        handleSelect={handleSelect}
        fromOrTo={selectedFromOrTo}
      ></FilterCadenceRangeModal>
    </>
  );
};

export default FilterCadenceRange;
