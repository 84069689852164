import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import ReleaseNotesPage from "pages/ReleaseNotesPage";

//Fonts
import "@sas/filament-fonts/anova/Anova-Bold.woff2";
import "@sas/filament-fonts/anova/Anova-BoldItalic.woff2";
import "@sas/filament-fonts/anova/Anova-Italic.woff2";
import "@sas/filament-fonts/anova/Anova-Light.woff2";
import "@sas/filament-fonts/anova/Anova-LightItalic.woff2";
import "@sas/filament-fonts/anova/Anova-Regular.woff2";

import "@sas/filament-fonts/anova-ui/AnovaUI-Bold.woff2";
import "@sas/filament-fonts/anova-ui/AnovaUI-BoldItalic.woff2";
import "@sas/filament-fonts/anova-ui/AnovaUI-Italic.woff2";
import "@sas/filament-fonts/anova-ui/AnovaUI-Light.woff2";
import "@sas/filament-fonts/anova-ui/AnovaUI-LightItalic.woff2";
import "@sas/filament-fonts/anova-ui/AnovaUI-Regular.woff2";

function App() {
  return (
    <>
      <Router>
        <Switch>
          {/* Release Notes Page */}
          <Route exact path="/releasenotes" component={ReleaseNotesPage} />

          {/* Default */}
          <Route path="/" component={ReleaseNotesPage} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
