//Node Modules
import axios from "axios";

//Types
import { FilterDto } from "types/filterDto";
import { WrapperData } from "types/wrapperData";
import { FilterResultWrapper } from "types/filterResultWrapper";
import { Settings } from "types/settings";

const BASE_URL = "/releaseNotes";
const INFO_URL = "/info";

export function wrapperData(): Promise<WrapperData> {
  return axios
    .get<WrapperData>(`${BASE_URL}/wrapper`)
    .then((result) => result.data);
}

export function releaseNotes(
  filterDto: FilterDto,
): Promise<FilterResultWrapper[]> {
  return axios
    .post<FilterResultWrapper[]>(`${BASE_URL}`, filterDto)
    .then((result) => result.data);
}

export function settings(locale: String): Promise<Settings> {
  return axios
    .get<Settings>(`${INFO_URL}`, { params: { locale } })
    .then((result) => result.data);
}
