import React, { useEffect, useState } from "react";

//Components
import { useTranslation } from "react-i18next";
import { FilterResultWrapper } from "types/filterResultWrapper";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./FilterApplyResetPanel.module.scss";

interface Props {
  applyFilters: () => void;
  resetFilters: () => void;
  filterApplied?: boolean;
  releaseNotes?: FilterResultWrapper[];
  selectedOfferings: string[];
  selectedCategories: string[];
  selectedCadences: string[];
  selectedReleaseNoteTypes: string[];
}

const FilterApplyResetPanel = ({
  applyFilters,
  resetFilters,
  filterApplied,
  releaseNotes,
  selectedOfferings,
  selectedCategories,
  selectedCadences,
  selectedReleaseNoteTypes,
}: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  // releaseNotes might have been updated from the parent. Update it accordingly
  const [updatedReleaseNotes, setUpdatedReleaseNotes] = useState(releaseNotes);
  useEffect(() => {
    setUpdatedReleaseNotes(releaseNotes);
  }, [releaseNotes]);

  //Use useEffect hook to keep track of showMessageBox state: when first load with zero release notes, show message box;
  //After filters are applied, regardless having or not having release notes, do not show message box.
  const messageBox = updatedReleaseNotes?.length === 0 && !filterApplied;
  const [showMessageBox, setShowMessageBox] = useState<boolean>(
    updatedReleaseNotes?.length === 0 && !filterApplied ? true : false
  );
  useEffect(() => {
    setShowMessageBox(messageBox);
  }, [messageBox]);

  const hideMessageBox = () => {
    setShowMessageBox(false);
  };

  const disableApplyFilters = () => {
    if (
      (selectedOfferings.length > 0 || selectedCategories.length > 0) &&
      selectedCadences.length > 0 &&
      selectedReleaseNoteTypes.length > 0
    ) {
      return false;
    }
    return true;
  };

  const disableResetFilters = () => {
    if (
      selectedOfferings.length === 0 &&
      selectedCategories.length === 0 &&
      selectedCadences.length === 0 &&
      selectedReleaseNoteTypes.length === 0
    ) {
      return true;
    }
    return false;
  };

  const applyFiltersClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    applyFilters();
  };
  
  const keypressHandler = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      e.preventDefault();
      e.stopPropagation();
      let target = e.target as HTMLElement;
      if (target.id === "btnApplyFilters") {
        applyFilters();
      } else if (target.id === "btnResetFilters") {
        resetFilters();
      }
    }
  };

  const divMessageBox = (
    <div className={styles.divMessageBoxTable}>
      <table className={styles.messageBoxTable}>
        <tbody>
          <tr>
            <td valign="top">
              <img
                className={styles.iconInfo}
                src="images/informationStatus.svg"
                alt={t("releasenotes-gui-icu.releasenotes.message.msg")}
              />
            </td>
            <td>
              <label className={styles.message}>
                {t("releasenotes-gui-icu.releasenotes.message_box.msg")}
              </label>
            </td>
            <td valign="top" className={styles.tdClose}>
              <img
                className={styles.closeIcon}
                role="button"
                src="images/close.svg"
                onClick={hideMessageBox}
                alt={t("releasenotes-gui-icu.releasenotes.close.msg")}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const buttonApply = (
    <button
      type="button"
      className={`${styles.button} ${styles.marginRight} ${
        disableApplyFilters()
          ? styles.buttonApplyFiltersDisabled
          : styles.buttonApplyFilters
      }`}
      onClick={applyFiltersClick}
      onKeyDown={keypressHandler}
      id="btnApplyFilters"
      aria-label={t("releasenotes-gui-icu.releasenotes.apply_filters.msg")}
      disabled={disableApplyFilters()}
    >
      {t("releasenotes-gui-icu.releasenotes.apply_filters.msg")}
    </button>
  );
  const buttonReset = (
    <button
      type="button"
      className={`${styles.button} ${styles.marginLeft} ${
        disableResetFilters()
          ? styles.buttonResetFiltersDisabled
          : styles.buttonResetFilters
      }`}
      onClick={resetFilters}
      onKeyDown={keypressHandler}
      id="btnResetFilters"
      aria-label={t("releasenotes-gui-icu.releasenotes.reset_filters.msg")}
      disabled={disableResetFilters()}
    >
      {t("releasenotes-gui-icu.releasenotes.reset_filters.msg")}
    </button>
  );

  return (
    <>
      {isSmallDevice ? (
        <div className={styles.divBottomPanelSmallDevice}>
          {buttonApply}
          {buttonReset}
        </div>
      ) : (
        <div className={styles.bottomPanel}>
          <div
            className={`${styles.divMessageBox} ${
              showMessageBox ? "" : "invisible"
            }`}
          >
            {divMessageBox}
          </div>
          <div className={styles.buttonInnerDiv}>
            {buttonApply}
            {buttonReset}
          </div>
        </div>
      )}
    </>
  );
};

export default FilterApplyResetPanel;
