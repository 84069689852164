import React from "react";

//Node Modules
import { useTranslation } from "react-i18next";
import { Image } from "react-bootstrap";

//Components
import LinkButton from "components/LinkButton";

//Styles
import styles from "./ExpandCollapseIcon.module.scss";

interface Props {
  id: string;
  ariaLabel: string;
  expand: boolean;
  handleResultToggle: (id: string) => void;
}

const ExpandCollapseIcon = ({
  id,
  ariaLabel,
  expand,
  handleResultToggle,
}: Props) => {
  const { t } = useTranslation();
  const elementId = expand ? "expand_" + id : "collapse_" + id;
  const imgSrc = expand
    ? "images/arrowExpanded.svg"
    : "images/arrowCollapsed.svg";
  const imgAlt = expand
    ? t("releasenotes-gui-icu.releasenotes.result_expand.msg")
    : t("releasenotes-gui-icu.releasenotes.result_expand.msg");

  const keypressHandler = (e: React.KeyboardEvent) => {
    //13: Enter key; 32: Space key
    if (e.keyCode === 13 || e.keyCode === 32) {
      let target = e.target as HTMLElement;
      let id = target.id.split("_")[1];
      handleResultToggle(id);
      e.preventDefault();
    }
  };

  return (
    <>
      <LinkButton
        id={elementId}
        onKeyDown={keypressHandler}
        onClick={() => handleResultToggle(id)}
        aria-label={ariaLabel}
        aria-expanded={expand}
      >
        <Image className={styles.toggleIcon} src={imgSrc} alt={imgAlt} />
      </LinkButton>
    </>
  );
};

export default ExpandCollapseIcon;
