//import React, { useState } from 'react'

//Components
import RadioButton from "components/RadioButton";

//Types
import { Cadence } from "types/wrapperData";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./FilterCadenceList.module.scss";

interface Props {
  paramCadence?: string;
  selectedCadences: string[];
  versions?: Cadence[];
  typeOfCadence: string;
  handleChange: (codeText: string, type: string) => void;
}

const FilterCadenceList = ({
  paramCadence,
  selectedCadences,
  versions,
  typeOfCadence,
  handleChange,
}: Props) => {
  const isSmallDevice = useIsSmallDevice();

  const findSelectedItem = (codeText: string): boolean => {
    return selectedCadences.includes(codeText);
  };

  return (
    <>
      <div className={styles.cadenceList} role="radiogroup">
        {versions?.map((version) => {
          return (
            <div
              key={version.codeText}
              className={isSmallDevice ? styles.smallDeviceItem : styles.item}
            >
              <RadioButton
                id={version.codeText}
                label={version.displayValue}
                title={version.displayValue}
                typeOfCadence={typeOfCadence}
                isChecked={findSelectedItem(version.codeText)}
                handleChange={handleChange}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FilterCadenceList;
