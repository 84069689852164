import React from "react";

//Node Modules
import Modal from "react-bootstrap/Modal";

//Types
import { Cadence } from "types/wrapperData";

//Styles
import styles from "./FilterCadenceRangeModal.module.scss";

interface Props {
  show: boolean;
  versions?: Cadence[];
  onHide: () => void;
  fromOrTo: string;
  handleSelect: (codeText: string, fromOrTo: string) => void;
}

const FilterCadenceRangeModal = ({
  show,
  versions,
  onHide,
  fromOrTo,
  handleSelect,
}: Props) => {
  const handleClick = (version: string) => {
    handleSelect(fromOrTo, version);
    onHide();
  };

  //13: Enter key, 32: Space key
  function keypressHandler(version: string, e: React.KeyboardEvent) {
    if (e.keyCode === 13 || e.keyCode === 32) {
      handleSelect(fromOrTo, version);
      onHide();
    }
  }

  return (
    <>
      <div className={styles.modalWindow}>
        <Modal
          className={styles.modalWindow}
          show={show}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          animation={false}
          dialogClassName={styles.modalWindow}
          scrollable={true}
        >
          <Modal.Body className={styles.modalBody}>
            <div className={styles.versionDiv}>
              {versions?.map((version) => {
                return (
                  <button
                    className={styles.row}
                    key={fromOrTo + version.codeText}
                    onClick={() => handleClick(version.codeText)}
                    onKeyDown={(e) => keypressHandler(version.codeText, e)}
                  >
                    {version.displayValue}
                  </button>
                );
              })}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default FilterCadenceRangeModal;
