import React from "react";

//Node Modules
import { useTranslation } from "react-i18next";

//Styles
import styles from "./Loading.module.scss";

const Loading = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.loading}>
      <div className={styles.spinner}>
        {Array(12)
          .fill(null)
          .map((_, i) => (
            <div key={i} />
          ))}
      </div>
      <div className={styles.text}>{t("releasenotes-gui-icu.spinner.msg")}</div>
    </div>
  );
};

export default Loading;
