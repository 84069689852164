import React from "react";

//Node Modules
import { useTranslation } from "react-i18next";

//Components
import FilterCadenceList from "components/FilterCadenceList";
import FilterCadenceRange from "components/FilterCadenceRange";
import RadioButton from "components/RadioButton";

//Util
import {
  FILTER_LTS,
  FILTER_STABLE,
  FILTER_LTS_INDIVIDUAL,
  FILTER_STABLE_INDIVIDUAL,
  FILTER_LTS_RANGE,
  FILTER_STABLE_RANGE,
} from "util/constants";

//Types
import { WrapperData } from "types/wrapperData";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./FilterCadencePanel.module.scss";

interface Props {
  paramCadence?: string;
  wrapperData?: WrapperData;
  selectedCadences: string[];
  handleChange: (codeText: string, type: string, fromOrTo?: string) => void;
  selectedCadenceRange?: string;
  selectedCadenceRangeFrom?: string;
  selectedCadenceRangeTo?: string;
  selectedCadenceType?: string;
  showMessageBox?: boolean;
}

const FilterCadencePanel = ({
  paramCadence,
  wrapperData,
  selectedCadences,
  handleChange,
  selectedCadenceRange,
  selectedCadenceRangeFrom,
  selectedCadenceRangeTo,
  selectedCadenceType,
  showMessageBox,
}: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  return (
    <>
      <div className={isSmallDevice ? styles.smallDevicePanel : styles.panel}>
        <div className={styles.cadenceDiv}>
          <div className={styles.sectionTitle}>
            {t("releasenotes-gui-icu.releasenotes.filter_versions.msg")}
          </div>
          <div className={styles.descriptiveText}>
            {t("releasenotes-gui-icu.releasenotes.filter_select_a_cadence.msg")}
          </div>
          <div
            className={
              isSmallDevice && !showMessageBox
                ? styles.scrollDivMessageNotShown
                : styles.scrollDiv
            }
          >
            <div className={styles.subTitle}>
              {t("releasenotes-gui-icu.releasenotes.filter_cadence.msg")}
            </div>
            <div className={styles.padding}>
              <RadioButton
                id={FILTER_LTS}
                label={t(
                  "releasenotes-gui-icu.releasenotes.filter_version_lts.msg"
                )}
                title={t(
                  "releasenotes-gui-icu.releasenotes.filter_version_lts.msg"
                )}
                typeOfCadence={FILTER_LTS}
                isChecked={selectedCadenceType === FILTER_LTS ? true : false}
                handleChange={handleChange}
              />
            </div>
            <div className={styles.padding}>
              <RadioButton
                id={FILTER_LTS_RANGE}
                label={t(
                  "releasenotes-gui-icu.releasenotes.filter_version_lts_range.msg"
                )}
                title={t(
                  "releasenotes-gui-icu.releasenotes.filter_version_lts_range.msg"
                )}
                typeOfCadence={FILTER_LTS_RANGE}
                isChecked={
                  selectedCadenceType === FILTER_LTS_RANGE ? true : false
                }
                handleChange={handleChange}
              />
            </div>
            <div className={styles.padding}>
              <RadioButton
                id={FILTER_STABLE}
                label={t(
                  "releasenotes-gui-icu.releasenotes.filter_version_stable.msg"
                )}
                title={t(
                  "releasenotes-gui-icu.releasenotes.filter_version_stable.msg"
                )}
                typeOfCadence={FILTER_STABLE}
                isChecked={selectedCadenceType === FILTER_STABLE ? true : false}
                handleChange={handleChange}
              />
            </div>
            <div className={styles.padding}>
              <RadioButton
                id={FILTER_STABLE_RANGE}
                label={t(
                  "releasenotes-gui-icu.releasenotes.filter_version_stable_range.msg"
                )}
                title={t(
                  "releasenotes-gui-icu.releasenotes.filter_version_stable_range.msg"
                )}
                typeOfCadence={FILTER_STABLE_RANGE}
                isChecked={
                  selectedCadenceType === FILTER_STABLE_RANGE ? true : false
                }
                handleChange={handleChange}
              />
            </div>

            {selectedCadenceType === FILTER_LTS ||
            selectedCadenceType === FILTER_STABLE ? (
              <div
                className={`${styles.subTitle} ${styles.padding} ${styles.paddingTop}`}
              >
                {t(
                  "releasenotes-gui-icu.releasenotes.filter_select_a_version.msg"
                )}
              </div>
            ) : (
              ""
            )}
            {selectedCadenceType === FILTER_LTS_RANGE ||
            selectedCadenceType === FILTER_STABLE_RANGE ? (
              <div
                className={`${styles.subTitle} ${styles.padding} ${styles.paddingTop}`}
              >
                {t(
                  "releasenotes-gui-icu.releasenotes.filter_select_a_range.msg"
                )}
              </div>
            ) : (
              ""
            )}

            {selectedCadenceType === FILTER_LTS ? (
              <div className={styles.cadenceListDiv}>
                <FilterCadenceList
                  paramCadence={paramCadence}
                  selectedCadences={selectedCadences}
                  versions={wrapperData?.ltsVersions}
                  typeOfCadence={FILTER_LTS_INDIVIDUAL}
                  handleChange={handleChange}
                ></FilterCadenceList>
              </div>
            ) : (
              ""
            )}

            {selectedCadenceType === FILTER_LTS_RANGE ? (
              <div>
                <FilterCadenceRange
                  selectedCadences={selectedCadences}
                  versions={wrapperData?.ltsVersions}
                  typeOfCadence={FILTER_LTS_RANGE}
                  handleChange={handleChange}
                  selectedCadenceRange={selectedCadenceRange}
                  selectedCadenceRangeFrom={selectedCadenceRangeFrom}
                  selectedCadenceRangeTo={selectedCadenceRangeTo}
                ></FilterCadenceRange>
              </div>
            ) : (
              ""
            )}

            {selectedCadenceType === FILTER_STABLE ? (
              <div>
                <FilterCadenceList
                  paramCadence={paramCadence}
                  selectedCadences={selectedCadences}
                  versions={wrapperData?.stableVersions}
                  typeOfCadence={FILTER_STABLE_INDIVIDUAL}
                  handleChange={handleChange}
                ></FilterCadenceList>
              </div>
            ) : (
              ""
            )}

            {selectedCadenceType === FILTER_STABLE_RANGE ? (
              <div>
                <FilterCadenceRange
                  selectedCadences={selectedCadences}
                  versions={wrapperData?.stableVersions}
                  typeOfCadence={FILTER_STABLE_RANGE}
                  handleChange={handleChange}
                  selectedCadenceRange={selectedCadenceRange}
                  selectedCadenceRangeFrom={selectedCadenceRangeFrom}
                  selectedCadenceRangeTo={selectedCadenceRangeTo}
                ></FilterCadenceRange>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterCadencePanel;
