import React from "react";

//Components
import SASIcon, { SVGIconDescriptor } from "./SASIcon";

// Node Modules
import ArrowCollapsed from "@nova-design/react-svg/icons/ArrowCollapsed";
import ArrowExpanded from "@nova-design/react-svg/icons/ArrowExpanded";
import ArrowSmallDownBtn from "@nova-design/react-svg/icons/ArrowSmallDownBtn";
import BannerSASLogo from "@nova-design/react-svg/svgs/BannerSASLogo";
import BannerSearch from "@nova-design/react-svg/icons/BannerSearch";
import Checkmark from "@nova-design/react-svg/icons/Checkmark";
import ClearSearch from "@nova-design/react-svg/icons/ClearSearch";
import Close from "@nova-design/react-svg/icons/Close";
import CodeBlock from "@nova-design/react-svg/icons/CodeBlock";
import Copy from "@nova-design/react-svg/icons/Copy";
import DisclosureCollapse from "@nova-design/react-svg/icons/DisclosureCollapse";
import DisclosurePrevious from "@nova-design/react-svg/icons/DisclosurePrevious";
import DisclosureNext from "@nova-design/react-svg/icons/DisclosureNext";
import Export from "@nova-design/react-svg/icons/Export";
import File from "@nova-design/react-svg/icons/File";
import Filter from "@nova-design/react-svg/icons/Filter";
import Globe from "@nova-design/react-svg/icons/Globe";
import LockClosed from "@nova-design/react-svg/icons/LockClosed";
import LockOpen from "@nova-design/react-svg/icons/LockOpen";
import MaximizeView from "@nova-design/react-svg/icons/MaximizeView";
import MediumPriorityStatus from "@nova-design/react-svg/svgs/MediumPriorityStatus";
import OperatorMinus from "@nova-design/react-svg/icons/OperatorMinus";
import OperatorPlus from "@nova-design/react-svg/icons/OperatorPlus";
import Overflow from "@nova-design/react-svg/icons/Overflow";
import PdfFile from "@nova-design/react-svg/icons/PdfFile";
import SideMenuButton from "@nova-design/react-svg/icons/SideMenuButton";
import SortArrowPrimaryDown from "@nova-design/react-svg/icons/SortArrowPrimaryDown";
import SortArrowPrimaryUp from "@nova-design/react-svg/icons/SortArrowPrimaryUp";
import TreeExpandClosed from "@nova-design/react-svg/icons/TreeExpandClosed";
import TreeExpandOpened from "@nova-design/react-svg/icons/TreeExpandOpened";
import UserGroup from "@nova-design/react-svg/icons/UserGroup";
import Video from "@nova-design/react-svg/icons/Video";
import InformationStatus from "@nova-design/react-svg/svgs/InformationStatus";
import OverflowLite from "@nova-design/react-svg/icons/OverflowLite";

const withIcon = (icon: SVGIconDescriptor) => () =>
  React.createElement(SASIcon, { icon });

export const CollectionTreeExpandClosedIcon = withIcon(ArrowCollapsed);
export const CollectionTreeExpandOpenedIcon = withIcon(ArrowExpanded);
export const ArrowDownIcon = withIcon(ArrowSmallDownBtn);
export const SASLogoIcon = withIcon(BannerSASLogo);
export const SearchIcon = withIcon(BannerSearch);
export const CheckmarkIcon = withIcon(Checkmark);
export const ClearSearchIcon = withIcon(ClearSearch);
export const CloseIcon = withIcon(Close);
export const CodeBlockIcon = withIcon(CodeBlock);
export const CopyIcon = withIcon(Copy);
export const TopIcon = withIcon(DisclosureCollapse);
export const PreviousIcon = withIcon(DisclosurePrevious);
export const NextIcon = withIcon(DisclosureNext);
export const ExportIcon = withIcon(Export);
export const FileIcon = withIcon(File);
export const FilterIcon = withIcon(Filter);
export const GlobeIcon = withIcon(Globe);
export const LockedIcon = withIcon(LockClosed);
export const UnlockedIcon = withIcon(LockOpen);
export const MaximizeViewIcon = withIcon(MaximizeView);
export const MediumPriorityStatusIcon = withIcon(MediumPriorityStatus);
export const OperatorMinusIcon = withIcon(OperatorMinus);
export const OperatorPlusIcon = withIcon(OperatorPlus);
export const OverflowIcon = withIcon(Overflow);
export const PdfFileIcon = withIcon(PdfFile);
export const MenuIcon = withIcon(SideMenuButton);
export const SortArrowDownIcon = withIcon(SortArrowPrimaryDown);
export const SortArrowUpIcon = withIcon(SortArrowPrimaryUp);
export const TreeExpandClosedIcon = withIcon(TreeExpandClosed);
export const TreeExpandOpenedIcon = withIcon(TreeExpandOpened);
export const UserGroupIcon = withIcon(UserGroup);
export const VideoIcon = withIcon(Video);
export const InformationStatusIcon = withIcon(InformationStatus);
export const OverflowLiteIcon = withIcon(OverflowLite);
