import React from "react";

//Node Modules
import { useTranslation } from "react-i18next";

//Components
import { CloseIcon } from "components/SASIcon";
import { InformationStatusIcon } from "components/SASIcon";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./MessageBox.module.scss";

interface Props {
  showMessageBox: boolean;
  setShowMessageBox: (show: boolean) => void;
}

const MessageBox = ({ showMessageBox, setShowMessageBox }: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  return (
    <>
      <div
        className={`${styles.messageBoxDiv} ${
          showMessageBox
            ? ""
            : isSmallDevice
              ? styles.messageBoxDivHide
              : "invisible"
        }`}
      >
        <div className={styles.infoIconDiv}>
          <InformationStatusIcon />
        </div>
        <div className={styles.messageDiv}>
          {t("releasenotes-gui-icu.releasenotes.message_box.msg")}
          <div className={styles.closeDiv}>
            <button
              type="button"
              className={`close ${styles.close}`}
              onClick={() => setShowMessageBox(false)}
              aria-label={t("releasenotes-gui-icu.modal.close_button.msg")}
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageBox;
