import React from "react";

//Styles
import styles from "./LinkButton.module.scss";

const LinkButton = React.forwardRef((props: any, ref) => {
  return (
    <button
      {...props}
      className={`${props.className} ${styles.button}`}
      ref={ref}
    />
  );
});

export default LinkButton;
