export default function parse(data: any): Record<string, string> {
  if (typeof data !== "string") {
    return data;
  }

  const properties = data as string;

  return properties
    .replace(/\\u([0-9A-F]{4})/g, (match, capture) => {
      // The unicode character is being read as an escaped \\u rather than \u
      return String.fromCharCode(parseInt(`0x${capture}`, 16));
    })
    .split("\n")
    .filter(Boolean)
    .reduce((acc: Record<string, string>, line: string) => {
      const [key, value] = line.split("=").map((item) => item.trim());

      if (key && key.length) {
        acc[key] = value;
      }

      return acc;
    }, {});
}
