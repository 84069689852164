import React from "react";

//Styles
import styles from "./HorizontalDivider.module.scss";

const HorizontalDivider = () => {
  return <hr className={styles.horizontalDivider}></hr>;
};

export default HorizontalDivider;
