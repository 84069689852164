import { createContext } from "react";

export const SelectedCadenceRangeContext = createContext<string | undefined>(
  undefined,
);
export const SelectedCadenceRangeFromContext = createContext<
  string | undefined
>(undefined);
export const SelectedCadenceRangeToContext = createContext<string | undefined>(
  undefined,
);
