import React from "react";

//Components
import ResultProduct from "components/ResultProduct";
import ResultCategory from "components/ResultCategory";
import ExpandCollapseIcon from "components/ExpandCollapseIcon";

//Types
import { FilterResultOfferingWrapper } from "types/filterResultWrapper";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./ResultOffering.module.scss";

interface Props {
  cadence: string;
  offeringReleaseNotes: FilterResultOfferingWrapper[];
  expandedResults: string[];
  handleResultToggle: (id: string) => void;
}

const ResultOffering = ({
  cadence,
  offeringReleaseNotes,
  expandedResults,
  handleResultToggle,
}: Props) => {
  const isSmallDevice = useIsSmallDevice();

  const getId = (codeText: string): string => {
    return cadence + "-offering-" + codeText;
  };

  const findItem = (id: string): boolean => {
    return !expandedResults.includes(id);
  };

  return (
    <>
      {offeringReleaseNotes?.map((offering) => {
        return offering.isOffering ? (
          // Render Offering
          <div
            className={
              isSmallDevice ? styles.offeringSmallDevice : styles.offering
            }
            key={offering.offeringCodeText}
          >
            <div className={styles.innerDiv}>
              <div className={styles.button}>
                <ExpandCollapseIcon
                  id={getId(offering.offeringCodeText)}
                  expand={
                    !findItem(getId(offering.offeringCodeText)) ? true : false
                  }
                  handleResultToggle={handleResultToggle}
                  ariaLabel={offering.offering}
                ></ExpandCollapseIcon>
              </div>
              <div className={styles.header}>{offering.offering}</div>
            </div>
            {!findItem(getId(offering.offeringCodeText)) && (
              <div id={getId(offering.offeringCodeText)}>
                <ResultProduct
                  cadence={cadence}
                  offering={offering.offeringCodeText}
                  productReleaseNotes={offering.productReleaseNotes}
                  expandedResults={expandedResults}
                  handleResultToggle={handleResultToggle}
                ></ResultProduct>
              </div>
            )}
          </div>
        ) : (
          // Render Category
          <ResultCategory
            cadence={cadence}
            productReleaseNotes={offering.productReleaseNotes}
            key={offering.offeringCodeText}
            expandedResults={expandedResults}
            handleResultToggle={handleResultToggle}
          ></ResultCategory>
        );
      })}
    </>
  );
};

export default ResultOffering;
