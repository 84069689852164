//Node Modules
import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

//Util
import parseProperties from "util/properties-to-json-parser";

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    lng: "en",
    fallbackLng: "en",

    keySeparator: false,
    interpolation: {
      escapeValue: true,
    },

    backend: {
      loadPath: (lngs: string[]) => {
        if (lngs[0] === "en") {
          return "./l10n/releasenotes-gui-icu.properties";
        } else {
          return "./l10n/releasenotes-gui-icu_{{lng}}.properties";
        }
      },
      parse: parseProperties,
    },

    react: {
      useSuspense: false,
      wait: true,
    },
  });

export const resolveLocale = (locale: string): string => {
  switch (locale.toLowerCase()) {
    case "zh-cn": {
      return "zh-hans";
    }
    case "zh-tw": {
      return "zh-hant";
    }
    case "pt-br": {
      return "pt-br";
    }
    default: {
      return locale;
    }
  }
};

/**
 * Handle switching languages, including any langauge overrides
 *
 * @param lang Language to set
 */
export const changeLanguage = (lang: string) => {
  i18n.changeLanguage(resolveLocale(lang));
};

export default i18n;
