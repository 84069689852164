import React from "react";

//Node Modules
import { useTranslation } from "react-i18next";

//Components
import ResultCadence from "components/ResultCadence";
import Loading from "components/Loading";

//Types
import { FilterResultWrapper } from "types/filterResultWrapper";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./ResultContainer.module.scss";

interface Props {
  releaseNotes?: FilterResultWrapper[];
  hasQueryParameter: boolean;
  searchByFilters: boolean;
  isLoading: boolean;
  expandedResults: string[];
  handleResultToggle: (id: string) => void;
}

const ResultContainer = ({
  releaseNotes,
  hasQueryParameter,
  searchByFilters,
  isLoading,
  expandedResults,
  handleResultToggle,
}: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : releaseNotes && releaseNotes.length > 0 ? (
        <div
          className={
            isSmallDevice
              ? styles.resultContainerSmallDevice
              : styles.resultContainer
          }
        >
          <ResultCadence
            releaseNotes={releaseNotes}
            expandedResults={expandedResults}
            handleResultToggle={handleResultToggle}
          ></ResultCadence>
        </div>
      ) : !hasQueryParameter && !searchByFilters ? (
        ""
      ) : (
        <div className={styles.notFoundDiv}>
          <div className={styles.notFound}>
            <img
              src="images/zeroStateSearch.svg"
              alt={t(
                "releasenotes-gui-icu.releasenotes.no_release_note_found.msg",
              )}
            ></img>
          </div>
          <div className={`${styles.notFound} ${styles.notFoundLabel}`}>
            {t("releasenotes-gui-icu.releasenotes.no_release_note_found.msg")}
          </div>
        </div>
      )}
    </>
  );
};

export default ResultContainer;
