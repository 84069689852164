import React from "react";

//Node Modules
import { useTranslation } from "react-i18next";
import { Image } from "react-bootstrap";

//Components
import LinkButton from "components/LinkButton";

//Types
import { FilterResultWrapper } from "types/filterResultWrapper";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./ExpandCollapseResult.module.scss";

interface Props {
  releaseNotes?: FilterResultWrapper[];
  handleToggle: (expandAll: boolean) => void;
  expandedResults: string[];
}

const ExpandCollapseResult = ({
  handleToggle,
  releaseNotes,
  expandedResults,
}: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  function expandAll() {
    handleToggle(true);
  }
  function collapseAll() {
    handleToggle(false);
  }

  function keypressHandler(e: React.KeyboardEvent) {
    //13: Enter key, 32: Space key
    if (e.keyCode === 13 || e.keyCode === 32) {
      let target = e.target as HTMLElement;
      if (
        target.textContent ===
        t("releasenotes-gui-icu.releasenotes.expand_all.msg")
      ) {
        handleToggle(true);
      }
      if (
        target.textContent ===
        t("releasenotes-gui-icu.releasenotes.collapse_all.msg")
      ) {
        handleToggle(false);
      }
      e.preventDefault();
      e.stopPropagation();
    }
  }

  return (
    <>
      {releaseNotes !== undefined && releaseNotes?.length > 0 ? (
        <div
          className={isSmallDevice ? styles.toggleSmallDevice : styles.toggle}
        >
          <LinkButton
            type="button"
            className={styles.linkButton}
            onClick={expandAll}
            onKeyDown={keypressHandler}
            disabled={expandedResults.length > 0}
          >
            <Image
              className={
                expandedResults.length > 0
                  ? styles.linkButtonIconDisabled
                  : styles.linkButtonIcon
              }
              src="images/showAll.svg"
            />
            <label
              className={
                expandedResults.length > 0
                  ? styles.linkButtonLabelDisabled
                  : styles.linkButtonLabel
              }
            >
              {t("releasenotes-gui-icu.releasenotes.expand_all.msg")}
            </label>
          </LinkButton>
          {!isSmallDevice ? (
            <span className={styles.verticalDivider}></span>
          ) : (
            ""
          )}
          <LinkButton
            type="button"
            className={styles.linkButton}
            onClick={collapseAll}
            onKeyDown={keypressHandler}
            disabled={expandedResults.length === 0}
          >
            <Image
              className={
                expandedResults.length === 0
                  ? styles.linkButtonIconDisabled
                  : styles.linkButtonIcon
              }
              src="images/hideAll.svg"
            />
            <label
              className={
                expandedResults.length === 0
                  ? styles.linkButtonLabelDisabled
                  : styles.linkButtonLabel
              }
            >
              {t("releasenotes-gui-icu.releasenotes.collapse_all.msg")}
            </label>
          </LinkButton>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default ExpandCollapseResult;
