import React from "react";

//Components
import ResultOffering from "components/ResultOffering";

//Types
import { FilterResultWrapper } from "types/filterResultWrapper";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./ResultCadence.module.scss";

interface Props {
  releaseNotes?: FilterResultWrapper[];
  expandedResults: string[];
  handleResultToggle: (id: string) => void;
}

const ResultCadence = ({
  releaseNotes,
  expandedResults,
  handleResultToggle,
}: Props) => {
  //const { t } = useTranslation()
  const isSmallDevice = useIsSmallDevice();

  return (
    <>
      {releaseNotes?.map((cadence) => {
        return (
          <div className={styles.cadence} key={cadence.cadenceVersion}>
            <div
              className={`${styles.header} ${
                isSmallDevice ? styles.smallDevice : styles.desktop
              }`}
            >
              {cadence.cadenceVersion}
            </div>
            <ResultOffering
              cadence={cadence.cadenceVersionNoDots}
              offeringReleaseNotes={cadence.offeringReleaseNotes}
              expandedResults={expandedResults}
              handleResultToggle={handleResultToggle}
            ></ResultOffering>
          </div>
        );
      })}
    </>
  );
};

export default ResultCadence;
