import React, { useState, useEffect, useRef } from "react";

//Node modules
import axios from "axios";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { changeLanguage, resolveLocale } from "i18n";
import { useHistory, useLocation } from "react-router-dom";
import i18n from "i18next";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

//Components
import Header from "components/Header";
import FilterContainer from "components/FilterContainer";
import ResultContainer from "components/ResultContainer";
import ExpandCollapseResult from "components/ExpandCollapseResult";
import { TopIcon } from "components/SASIcon";

//Styles
import styles from "./ReleaseNotesContainer.module.scss";

//Types
import { WrapperData } from "types/wrapperData";
import { FilterResultWrapper } from "types/filterResultWrapper";
import { FilterDto } from "types/filterDto";

//Hooks
import { useIsSmallDevice } from "hooks";

//Api
import * as releaseNotesApi from "api/releaseNotes";

//Util
import { FILTER_LTS_RANGE, FILTER_STABLE_RANGE } from "util/constants";

import { DisableShareUrlButtonContext } from "context/disable-share-url-button-context";

// To set proxy to local dev server in package.json:    "proxy":"http://localhost:8080"

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ReleaseNotesContainer = () => {
  // Get URL parameters
  const history = useHistory();
  const paramLocale = useQuery().get("locale") || undefined;
  const paramCadence = useQuery().get("cadence") || undefined;
  const paramOffering = useQuery().get("offering") || undefined;
  const paramOrder = useQuery().get("order") || undefined;
  const [locale, setLocale] = useState(paramLocale ? paramLocale : "");
  const isSmallDevice = useIsSmallDevice();

  const tempOfferings = useQuery().get("offerings") || undefined;
  const tempCategories = useQuery().get("categories") || undefined;
  const tempCadences = useQuery().get("cadences") || undefined;
  const tempTypes = useQuery().get("types") || undefined;
  var paramOfferings = tempOfferings ? convertStringToArray(tempOfferings) : [];
  var paramCategories = tempCategories
    ? convertStringToArray(tempCategories)
    : [];
  var paramCadences = tempCadences ? convertStringToArray(tempCadences) : [];
  var paramTypes = tempTypes ? convertStringToArray(tempTypes) : [];

  function convertStringToArray(input: string) {
    var inputArray = input?.replaceAll("[", "").replaceAll("]", "").split(",");
    for (var i = 0; i < inputArray.length; i++) {
      inputArray[i] = inputArray[i].replaceAll('"', "");
    }
    return inputArray;
  }

  useEffect(() => {
    //check to make sure lang is set correctly
    if (i18n.language !== resolveLocale(locale)) {
      i18n.changeLanguage(resolveLocale(locale));
    }
  }, [locale]);

  useEffect(() => {
    changeLanguage(locale);
  }, [locale]);

  // Get wrapperData and ReleaseNotes upon loading
  const { t } = useTranslation();
  let hasQueryParameter =
    paramCadence ||
    paramOffering ||
    paramOrder ||
    paramOfferings ||
    paramCategories ||
    paramCadences ||
    paramTypes
      ? true
      : false;
  const [wrapperData, setWrapperData] = useState<WrapperData>();
  const [releaseNotes, setReleaseNotes] = useState<FilterResultWrapper[]>([]);
  const [searchByFilters, setSearchByFilters] =
    useState<boolean>(!hasQueryParameter);
  const [selectedOfferings, setSelectedOfferings] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedReleaseNoteTypes, setSelectedReleaseNoteTypes] = useState<
    string[]
  >([]);
  const [selectedCadences, setSelectedCadences] = useState<string[]>(
    paramCadence ? [paramCadence] : []
  );
  const [selectedCadenceRange, setSelectedCadenceRange] = useState<string>();
  const [selectedCadenceRangeFrom, setSelectedCadenceRangeFrom] =
    useState<string>();
  const [selectedCadenceRangeTo, setSelectedCadenceRangeTo] =
    useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [expandFilter, setExpandFilter] = useState(false);

  // States to hold filter items AFTER clicking Apply Filters, which will be used in FilterSelected component
  const [offeringsAfterFiltering, setOfferingsAfterFiltering] = useState<
    string[]
  >([]);
  const [categoriesAfterFiltering, setCategoriesAfterFiltering] = useState<
    string[]
  >([]);
  const [releaseNoteTypesAfterFiltering, setReleaseNoteTypesAfterFiltering] =
    useState<string[]>([]);
  const [cadencesAfterFiltering, setCadencesAfterFiltering] = useState<
    string[]
  >(paramCadence ? [paramCadence] : []);

  // States to expand/collpase category, offering and products: anything exists in the array is the expanded items.
  const [expandedResults, setExpandedResults] = useState<string[]>([]);

  const [scrollVisible, setScrollVisible] = useState(false);
  const mainEl = useRef<HTMLDivElement>(null);
  function handleScroll(event: React.UIEvent) {
    const scrollTop = (event.target as HTMLElement).scrollTop;

    setScrollVisible(scrollTop >= 200);
  }

  function handleScrollToTop() {
    let el = document.getElementById("releaseNotesContainer");
    if (el) {
      el.scrollTop = 0;
    }
    setScrollVisible(false);
  }

  const handleFilters = (filterDto: FilterDto) => {
    setIsLoading(true);
    async function retrieveReleaseNotes() {
      await releaseNotesApi.releaseNotes(filterDto!).then((result) => {
        setReleaseNotes(result);
        setIsLoading(false);
        resetExpandedResults(result);
      });
    }
    retrieveReleaseNotes();
    setSearchByFilters(true);
    setItemsAfterFilters();
    setExpandFilter(false);

    // Set the previously-disabled ShareUrl button to enabled
    setDisableShareUrlButton(false);
  };

  const handleExpandFilter = () => {
    setExpandFilter(!expandFilter);
  };

  const handleResetFilters = () => {
    setSelectedOfferings([]);
    setSelectedCategories([]);
    setSelectedReleaseNoteTypes([]);
    setSelectedCadences([]);
  };

  const handleSelection = (
    offerings?: string[],
    categories?: string[],
    releaseNoteTypes?: string[],
    cadences?: string[]
  ) => {
    if (offerings) {
      setSelectedOfferings(offerings);
    }
    if (categories) {
      setSelectedCategories(categories);
    }
    if (releaseNoteTypes) {
      setSelectedReleaseNoteTypes(releaseNoteTypes);
    }
    if (cadences !== undefined) {
      setSelectedCadences(cadences);
    }
  };

  // When expand/collase image is toggled, either remove the item from hidden array(to show) or add the item into the array (to hide)
  const handleResultToggle = (id: string) => {
    let results = _.cloneDeep(expandedResults);
    if (results.includes(id)) {
      results = results.filter((e) => e !== id);

      // If the ID has an expanded hierarchy item underneath, for example, an single product underneath an offering, need to remove it too.
      // This child ID always starts with the same parent ID with additional product/releaseNoteType info appended.
      results.forEach((child) => {
        if (child.startsWith(id)) {
          results = results.filter((e) => e !== child);
        }
      });
    } else {
      results.push(id);
    }
    setExpandedResults(results);
  };

  // When expand all is toggled, add all items into expandedResults; if collapse all is clicked, clear out all items.
  const handleToggle = (expandAll: boolean) => {
    if (expandAll) {
      let element = document.getElementById("divResult");
      element?.scrollIntoView();
    }
    if (!expandAll) {
      setExpandedResults([]);
    } else {
      let results: string[];
      results = [];
      releaseNotes.forEach((item) => {
        let cadence = item.cadenceVersionNoDots;
        item.offeringReleaseNotes.forEach((offeringItem) => {
          let offeringItemId =
            cadence + "-offering-" + offeringItem.offeringCodeText;
          results.push(offeringItemId);
          offeringItem.productReleaseNotes.forEach((productItem) => {
            if (offeringItem.isOffering) {
              let productItemId =
                cadence +
                "-offering-" +
                offeringItem.offeringCodeText +
                "-product-" +
                productItem.productIdentifierCodeText;
              results.push(productItemId);

              // Loop through each release note types in the producty and add the release note type into expand list
              productItem.typeReleaseNotes.forEach((typeItem) => {
                let collapseTypeIconId =
                  productItemId +
                  "-releaseNoteType-" +
                  typeItem.releaseNoteTypeCodeText;
                results.push(collapseTypeIconId);
              });
            } else {
              let categoryItemId =
                cadence + "-category-" + productItem.productIdentifierCodeText;
              results.push(categoryItemId);

              // Loop through each release note types in the category and add the release note type into expand lists
              productItem.typeReleaseNotes.forEach((typeItem) => {
                let collapseTypeIconId =
                  categoryItemId +
                  "-releaseNoteType-" +
                  typeItem.releaseNoteTypeCodeText;
                results.push(collapseTypeIconId);
              });
            }
          });
        });
      });
      setExpandedResults(results);
    }
  };

  // Expand (add id into expandResults) or collapse (clear out expandedResults) results based on the return set.
  const resetExpandedResults = (releaseNotes: FilterResultWrapper[]) => {
    // Multiple cadences, collpase all (each element in releaseNotes is an object of FilterResultWrapper), grouped by cadence
    if (releaseNotes.length > 1) {
      setExpandedResults([]);
    }
    // One cadence, multiple products/categoties: collapse all
    if (
      releaseNotes.length === 1 &&
      releaseNotes.length === 1 &&
      releaseNotes[0].offeringReleaseNotes.length > 1
    ) {
      setExpandedResults([]);
    }
    // Only one cadence
    else if (
      releaseNotes.length === 1 &&
      releaseNotes[0].offeringReleaseNotes.length === 1
    ) {
      let item = releaseNotes[0].offeringReleaseNotes[0];
      let type = item.isOffering ? "-offering-" : "-category-";

      // Multiple offerings: clear out expandedResults to collpase all
      if (item.isOffering && releaseNotes[0].offeringReleaseNotes.length > 1) {
        setExpandedResults([]);
      }
      // Single offering but multiple products: expand offering but collapse products
      if (
        item.isOffering &&
        releaseNotes[0].offeringReleaseNotes.length === 1 &&
        item.productReleaseNotes.length > 1
      ) {
        let offeringId =
          releaseNotes[0].cadenceVersionNoDots +
          type +
          releaseNotes[0].offeringReleaseNotes[0].offeringCodeText;
        let result = [offeringId];
        setExpandedResults(result);
      }
      // Single offering, single product, multiple release notes types: expand offering, collapse product, collapse release note types
      if (
        item.isOffering &&
        releaseNotes[0].offeringReleaseNotes.length === 1 &&
        item.productReleaseNotes.length === 1 &&
        item.productReleaseNotes[0].typeReleaseNotes.length > 1
      ) {
        let offeringId =
          releaseNotes[0].cadenceVersionNoDots +
          type +
          releaseNotes[0].offeringReleaseNotes[0].offeringCodeText;
        let result = [offeringId];
        setExpandedResults(result);
      }
      // Single offering, single product, single release notes type: expand offering, expand product, expand release note type
      if (
        item.isOffering &&
        releaseNotes[0].offeringReleaseNotes.length === 1 &&
        item.productReleaseNotes.length === 1 &&
        item.productReleaseNotes[0].typeReleaseNotes.length === 1
      ) {
        let offeringId =
          releaseNotes[0].cadenceVersionNoDots +
          type +
          releaseNotes[0].offeringReleaseNotes[0].offeringCodeText;
        let productId =
          offeringId +
          "-product-" +
          releaseNotes[0].offeringReleaseNotes[0].productReleaseNotes[0]
            .productIdentifierCodeText;
        let releaseNoteTypeId =
          productId +
          "-releaseNoteType-" +
          item.productReleaseNotes[0]?.typeReleaseNotes[0]
            .releaseNoteTypeCodeText;
        let result = [offeringId];
        result.push(productId);
        result.push(releaseNoteTypeId);
        setExpandedResults(result);
      }

      // Multiple categories: collpase all
      if (!item.isOffering && item.productReleaseNotes.length > 1) {
        setExpandedResults([]);
      }
      // Single category, multiple release note types: expand category, collapse release note types
      if (
        !item.isOffering &&
        item.productReleaseNotes.length === 1 &&
        item.productReleaseNotes[0].typeReleaseNotes.length > 1
      ) {
        let categoryId =
          releaseNotes[0].cadenceVersionNoDots +
          type +
          item.productReleaseNotes[0].productIdentifierCodeText;
        let result = [categoryId];
        setExpandedResults(result);
      }
      // Single category, single release note type: expand category, expand release note type
      if (
        !item.isOffering &&
        item.productReleaseNotes.length === 1 &&
        item.productReleaseNotes[0].typeReleaseNotes.length === 1
      ) {
        let categoryId =
          releaseNotes[0].cadenceVersionNoDots +
          type +
          item.productReleaseNotes[0].productIdentifierCodeText;
        let result = [categoryId];
        if (
          releaseNotes[0].offeringReleaseNotes[0]?.productReleaseNotes[0]
            ?.typeReleaseNotes.length === 1
        ) {
          let releaseNoteTypeId =
            categoryId +
            "-releaseNoteType-" +
            item.productReleaseNotes[0]?.typeReleaseNotes[0]
              .releaseNoteTypeCodeText;
          result.push(releaseNoteTypeId);
        }
        setExpandedResults(result);
      }
    }
  };

  const setItemsAfterFilters = () => {
    setOfferingsAfterFiltering(selectedOfferings);
    setCategoriesAfterFiltering(selectedCategories);
    setReleaseNoteTypesAfterFiltering(selectedReleaseNoteTypes);
    setCadencesAfterFiltering(selectedCadences);
    setUrlParameters(
      selectedOfferings,
      selectedCategories,
      selectedReleaseNoteTypes,
      selectedCadences
    );
  };

  const setUrlParameters = (
    selectedOfferings: string[],
    selectedCategories: string[],
    selectedReleaseNoteTypes: string[],
    selectedCadences: string[]
  ) => {
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("offerings", JSON.stringify(selectedOfferings));
    currentUrlParams.set("categories", JSON.stringify(selectedCategories));
    currentUrlParams.set("cadences", JSON.stringify(selectedCadences));
    currentUrlParams.set("types", JSON.stringify(selectedReleaseNoteTypes));
    currentUrlParams.delete("offering");
    currentUrlParams.delete("cadence");
    currentUrlParams.delete("order");
    history.push(window.location.pathname + "?" + currentUrlParams.toString());
  };

  // This section is to handle the filter pre-selection: when URL parameters have offerings,
  // categories, cadences and release notes types, need to pre-select these filters when page is loaded.
  useEffect(() => {
    // If there is offering param but no cadence param, default the cadence to the latest stable and preselect it.
    const preselectCadence = (wrapperDataResult: WrapperData) => {
      if (paramOffering && !paramCadence) {
        setSelectedCadences([wrapperDataResult.stableVersions[0].codeText]);
        setSelectedOfferings([paramOffering]);
      }
    };

    // If cadence is passed in URL parameter, preselect offerings contained in the release notes
    const preselectOfferings = (releaseNotesResult: FilterResultWrapper[]) => {
      if (releaseNotesResult && releaseNotesResult.length > 0) {
        let offeringReleaseNotes = releaseNotesResult[0]?.offeringReleaseNotes;
        let offeringsContainedInResults: string[];
        offeringsContainedInResults = [];
        offeringReleaseNotes.forEach(function (offering) {
          if (offering.offeringCodeText !== "CATEGORY") {
            offeringsContainedInResults.push(offering.offeringCodeText);
          }
        });
        setSelectedOfferings(offeringsContainedInResults);
      }
    };

    // If cadence is passed in URL parameter, preselect all categories and release note types
    const preselectCategoriesAndTypes = (
      wrapperDataResult: WrapperData,
      releaseNotesResult: FilterResultWrapper[]
    ) => {
      if (
        wrapperDataResult &&
        releaseNotesResult &&
        releaseNotesResult.length > 0
      ) {
        let categories = _.cloneDeep(
          wrapperDataResult.categories.map((e) => e.codeText)
        );
        setSelectedCategories(categories ? categories : []);
        let releaseNotesTypes = _.cloneDeep(
          wrapperDataResult.releaseNoteTypes.map((e) => e.codeText)
        );
        setSelectedReleaseNoteTypes(releaseNotesTypes ? releaseNotesTypes : []);
      }
    };

    async function retrieveReleaseNotes(wrapperDataResult: WrapperData) {
      // If there are offerings, categories and cadences in URL parameters
      let filterDto = {};
      if (
        paramOfferings.length > 0 ||
        paramCategories.length > 0 ||
        paramCadences.length > 0
      ) {
        filterDto = {
          cadences: paramCadences,
          offerings: paramOfferings,
          categories: paramCategories,
          releaseNoteTypes: paramTypes,
          filterByVersionOnly: false,
        };
        await releaseNotesApi.releaseNotes(filterDto!).then(
          (releaseNoteResult) => {
            setReleaseNotes(releaseNoteResult);
            setSelectedCadences(paramCadences);

            // If paramCadences has multiple cadences, need to set the range properly. Set the rangeFrom to the
            // first element in the list and set rangeTo to the last element in the list.
            if (paramCadences.length > 1) {
              setSelectedCadenceRangeFrom(paramCadences[0]);
              setSelectedCadenceRangeTo(
                paramCadences[paramCadences.length - 1]
              );
              if (paramCadences[0].endsWith("LTS")) {
                setSelectedCadenceRange(FILTER_LTS_RANGE);
              } else {
                setSelectedCadenceRange(FILTER_STABLE_RANGE);
              }
            }
            setSelectedOfferings(paramOfferings);
            setSelectedCategories(paramCategories);
            setSelectedReleaseNoteTypes(paramTypes);
            setIsLoading(false);
            setExpandFilter(releaseNoteResult.length > 0 ? false : true);
            resetExpandedResults(releaseNoteResult);
          },
          () => {
            setIsLoading(false);
          }
        );
      }

      // If there is only cadence param, or order param
      else if (paramCadence || paramOrder) {
        filterDto = {
          order: paramOrder ? paramOrder : "",
          cadences: [paramCadence],
          offerings: [],
          categories: wrapperDataResult.categories.map((e) => e.codeText),
          releaseNoteTypes: wrapperDataResult.releaseNoteTypes.map(
            (e) => e.codeText
          ),
          filterByVersionOnly: paramCadence && !paramOffering ? true : false,
        };
        await releaseNotesApi.releaseNotes(filterDto!).then(
          (releaseNoteResult) => {
            setReleaseNotes(releaseNoteResult);
            preselectOfferings(releaseNoteResult);
            preselectCategoriesAndTypes(wrapperDataResult, releaseNoteResult);
            setIsLoading(false);
            setExpandFilter(releaseNoteResult.length > 0 ? false : true);
            resetExpandedResults(releaseNoteResult);
          },
          () => {
            setIsLoading(false);
          }
        );
      }

      // If there is only offering param, default the cadence to the latest stable and set it into filterDto.
      else if (paramOffering) {
        filterDto = {
          cadences: [wrapperDataResult.stableVersions[0].codeText],
          offerings: [paramOffering],
          categories: wrapperDataResult.categories.map((e) => e.codeText),
          releaseNoteTypes: wrapperDataResult.releaseNoteTypes.map(
            (e) => e.codeText
          ),
          filterByVersionOnly: false,
        };
        await releaseNotesApi.releaseNotes(filterDto!).then(
          (releaseNoteResult) => {
            setReleaseNotes(releaseNoteResult);
            preselectOfferings(releaseNoteResult);
            preselectCategoriesAndTypes(wrapperDataResult, releaseNoteResult);
            setIsLoading(false);
            setExpandFilter(releaseNoteResult.length > 0 ? false : true);
            resetExpandedResults(releaseNoteResult);
          },
          () => {
            setIsLoading(false);
          }
        );
      } else {
        setIsLoading(false);
        setExpandFilter(true);
      }
    }

    async function load() {
      await releaseNotesApi.settings(locale).then((settings) => {
        // Set up google analytics
        ReactGA.initialize(settings.googleAnalytics);
        ReactGA4.initialize(settings.googleAnalytics4);
        const url = `${window.location.pathname}${window.location.search}`;
        ReactGA.pageview(url);
        ReactGA4.send(url);

        //If locale isn't provided in parameter, get browser language and set it into request header.
        //Set Resolved locale (returned langugeUrl) into request header.
        let languageBrowser = settings.languageBrowser;
        let languageUrl = settings.languageUrl;
        let languageToUse = "";
        let currentUrlParams = new URLSearchParams(window.location.search);
        if (locale === "") {
          languageToUse = languageBrowser;
        } else if (
          locale !== settings.languageUrl &&
          settings.languageUrl !== ""
        ) {
          languageToUse = languageUrl;
        } else {
          languageToUse = locale;
        }

        currentUrlParams.set("locale", languageToUse);
        history.push(
          window.location.pathname + "?" + currentUrlParams.toString()
        );

        axios.defaults.headers.common["Accept-Language"] = languageToUse;
        if (i18n.language !== resolveLocale(languageToUse)) {
          i18n.changeLanguage(resolveLocale(languageToUse));
        }
        setLocale(languageToUse);
      });

      setIsLoading(true);
      await releaseNotesApi.wrapperData().then(
        (wrapperDataResult) => {
          setWrapperData(wrapperDataResult);
          preselectCadence(wrapperDataResult);
          retrieveReleaseNotes(wrapperDataResult);
        },
        () => {}
      );
    }
    load();
    setSearchByFilters(false);
    // Disable the ESLint Rule so supress the "Hook useEffect Has a Missing Dependency” Error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Localizing the title in html head
  useEffect(() => {
    let title = document.getElementsByTagName("title")[0];
    document.getElementsByTagName("head")[0].removeChild(title);
    let newTitle = document.createElement("title");
    newTitle.textContent = t(
      "releasenotes-gui-icu.releasenotes.head_title.msg"
    );
    document.getElementsByTagName("head")[0].appendChild(newTitle);
  });

  // Detect changes in fitlers so that disable/enable ShareUrl button accordingly
  const [disableShareUrlButton, setDisableShareUrlButton] =
    useState<boolean>(false);
  const hasFilterChanged = (filterChanged: boolean) => {
    setDisableShareUrlButton(filterChanged);
  };

  return (
    <>
      <DisableShareUrlButtonContext.Provider value={disableShareUrlButton}>
        <Header
          paramOrder={paramOrder}
          hideSasIcon={scrollVisible && isSmallDevice}
        ></Header>
        <article
          id="releaseNotesContainer"
          ref={mainEl}
          onScroll={handleScroll}
        >
          <FilterContainer
            paramCadence={paramCadence}
            paramOffering={paramOffering}
            wrapperData={wrapperData}
            releaseNotes={releaseNotes}
            handleFilters={handleFilters}
            handleResetFilters={handleResetFilters}
            handleSelection={handleSelection}
            searchByFilters={searchByFilters}
            selectedOfferings={selectedOfferings}
            selectedCategories={selectedCategories}
            selectedReleaseNoteTypes={selectedReleaseNoteTypes}
            selectedCadences={selectedCadences}
            offeringsAfterFiltering={offeringsAfterFiltering}
            categoriesAfterFiltering={categoriesAfterFiltering}
            releaseNoteTypesAfterFiltering={releaseNoteTypesAfterFiltering}
            cadencesAfterFiltering={cadencesAfterFiltering}
            expandFilter={expandFilter}
            handleExpandFilter={handleExpandFilter}
            range={selectedCadenceRange}
            rangeFrom={selectedCadenceRangeFrom}
            rangeTo={selectedCadenceRangeTo}
            hasFilterChanged={hasFilterChanged}
          ></FilterContainer>
          <div id="divResult">
            <ExpandCollapseResult
              releaseNotes={releaseNotes}
              handleToggle={handleToggle}
              expandedResults={expandedResults}
            ></ExpandCollapseResult>
            <ResultContainer
              releaseNotes={releaseNotes}
              hasQueryParameter={hasQueryParameter}
              searchByFilters={searchByFilters}
              isLoading={isLoading}
              expandedResults={expandedResults}
              handleResultToggle={handleResultToggle}
            ></ResultContainer>
            <button
              className={`${styles.scrollToTop} ${scrollVisible ? styles.visible : styles.hidden} ${isSmallDevice ? styles.smallDevice : styles.desktop}`}
              title={t("releasenotes-gui-icu.releasenotes.scroll_to_top.msg")}
              onClick={handleScrollToTop}
            >
              <TopIcon />
            </button>
          </div>
        </article>
      </DisableShareUrlButtonContext.Provider>
    </>
  );
};

export default ReleaseNotesContainer;
