import React from "react";

//Node Modules
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

//Hooks
import { useIsSmallDevice } from "hooks";

//Types
import { ReleaseNote } from "types/filterResultWrapper";

//Styles
import styles from "./ResultReleaseNote.module.scss";

interface Props {
  releaseNotes: ReleaseNote[];
}

const ResultReleaseNote = ({ releaseNotes }: Props) => {
  const { t } = useTranslation();
  const isSmallDevice = useIsSmallDevice();

  return (
    <>
      {releaseNotes?.map((note, index) => {
        return (
          <div
            className={
              isSmallDevice ? styles.releaseNoteSmallDevice : styles.releaseNote
            }
            key={index + "-" + note.title}
          >
            <div className={styles.title}>{note.title}</div>
            <span className={styles.description}>
              {note.description ? parse(note.description) : ""}
            </span>

            {note.documentLink ? (
              <a
                className={styles.documentLink}
                href={note.documentLink}
                rel="noreferrer"
                target="_blank"
              >
                {t("releasenotes-gui-icu.releasenotes.document_link_more.msg")}
              </a>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </>
  );
};

export default ResultReleaseNote;
