import React from "react";

//Components
import ResultReleaseNoteType from "components/ResultReleaseNoteType";
import ExpandCollapseIcon from "components/ExpandCollapseIcon";

//Types
import { FilterResultProductWrapper } from "types/filterResultWrapper";

//Hooks
import { useIsSmallDevice } from "hooks";

//Styles
import styles from "./ResultProduct.module.scss";

interface Props {
  cadence: string;
  offering: string;
  productReleaseNotes: FilterResultProductWrapper[];
  expandedResults: string[];
  handleResultToggle: (id: string) => void;
}

const ResultProduct = ({
  cadence,
  offering,
  productReleaseNotes,
  expandedResults,
  handleResultToggle,
}: Props) => {
  const isSmallDevice = useIsSmallDevice();

  const getId = (codeText: string): string => {
    return cadence + "-offering-" + offering + "-product-" + codeText;
  };

  const findItem = (id: string): boolean => {
    return !expandedResults.includes(id);
  };

  return (
    <>
      {productReleaseNotes?.map((product) => {
        return (
          <div key={product.productIdentifierCodeText}>
            <div
              className={
                isSmallDevice ? styles.productSmallDevice : styles.product
              }
            >
              <div className={styles.innerDiv}>
                <div>
                  <ExpandCollapseIcon
                    id={getId(product.productIdentifierCodeText)}
                    expand={
                      !findItem(getId(product.productIdentifierCodeText))
                        ? true
                        : false
                    }
                    handleResultToggle={handleResultToggle}
                    ariaLabel={product.productIdentifier}
                  ></ExpandCollapseIcon>
                </div>
                <div>
                  <span className={styles.header}>
                    {product.productIdentifier}
                  </span>
                </div>
              </div>
            </div>
            {!findItem(getId(product.productIdentifierCodeText)) && (
              <div
                id={getId(product.productIdentifierCodeText)}
                className={styles.releaseNoteTypeInProduct}
              >
                <ResultReleaseNoteType
                  expandCollapseIconId={getId(
                    product.productIdentifierCodeText
                  )}
                  typeReleaseNotes={product.typeReleaseNotes}
                  source="product"
                  expandedResults={expandedResults}
                  handleResultToggle={handleResultToggle}
                ></ResultReleaseNoteType>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default ResultProduct;
